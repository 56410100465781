import { profileApi } from '../../apis/strapiApi'
import backgroundImg from '../../assets/profile/background.jpg'
import { colors } from '../../colors'
import { Profile } from '../../types'
import {
  faSkype,
  faTelegram,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons'
import {
  IconDefinition,
  faEnvelope,
  faGamepad,
  faPhone,
  faRocket,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Button,
  Card,
  Divider,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PageContent } from '../../components'

interface Field {
  icon?: IconDefinition
  value?: string
  color?: string
  label: string
  href?: string
}

const ProfileItem = () => {
  const { profileName } = useParams()
  const [profile, setProfile] = useState<Profile>()

  //TODO: Use SWR to fetch articles
  useEffect(() => {
    if (profileName) {
      const getProfile = async () => {
        const data = await profileApi(profileName)
        setProfile(data?.data)
      }
      getProfile()
    }
  }, [profileName])

  console.log('profile::', profile?.attributes?.image)

  const fieldList: Field[] = [
    {
      label: 'Email',
      icon: faEnvelope,
      value: profile?.attributes?.email,
      href: `mailto:${profile?.attributes?.email}`,
      color: colors.gray[5],
    },
    {
      label: 'Phone',
      icon: faPhone,
      value: profile?.attributes?.phone,
      href: `tel:${profile?.attributes?.phone}`,
      color: colors.gray[5],
    },
    {
      label: 'Telegram',
      icon: faTelegram,
      value: profile?.attributes?.telegramId,
      href: `https://t.me/${profile?.attributes?.telegramId}`,
      color: '#0088CC',
    },
    {
      label: 'WhatsApp',
      icon: faWhatsapp,
      value: profile?.attributes?.whatsapp,
      color: '#2FA52D',
    },
    {
      label: 'Skype',
      icon: faSkype,
      value: profile?.attributes?.skype?.userId,
      href: profile?.attributes?.skype?.inviteURL,
      color: '#00AFF0',
    },
  ]

  const field = (item: Field) => {
    const { icon, value, color, label, href } = item
    return (
      <Link href={href}>
        <Button
          size="large"
          variant="contained"
          sx={{
            width: 1,
            justifyContent: 'start',
            gap: 1,
            background: color,
            ':hover, :focus': { background: color, opacity: 0.8 },
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 0.5,
              svg: { width: 16, color: colors.gray[12] },
            }}
          >
            {icon && <FontAwesomeIcon icon={icon} fixedWidth />}
          </Stack>

          <Stack>
            <Typography
              variant="caption"
              sx={{ textAlign: 'left', color: colors.gray[12] }}
            >
              {label}
            </Typography>
            <Typography
              variant="subHeadline"
              sx={{
                fontSize: 16,
                textTransform: 'lowercase',
                color: colors.gray[12],
              }}
            >
              {value}
            </Typography>
          </Stack>
        </Button>
      </Link>
    )
  }

  return (
    <Box
      sx={{
        position: 'relative',
        background: `url(${backgroundImg}) no-repeat center center`,
        backgroundSize: 'cover',
      }}
    >
      <PageContent sx={{ alignItems: 'center' }}>
        {profile && (
          <Card sx={{ width: 340, borderRadius: 4 }}>
            <Stack>
              <Stack
                sx={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  background:
                    'linear-gradient(297.11deg, #2A243A 56.61%, #110C20 98.21%)',
                  px: 2,
                  py: 4,
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    border: `4px solid ${colors.gray[12]}`,
                    borderRadius: '50%',
                  }}
                >
                  <Stack
                    sx={{
                      justifyContent: 'center',
                      width: 128,
                      height: 128,
                      m: 0.75,
                      borderRadius: '50%',
                      background: `url(${profile?.attributes?.image?.data.attributes.url}) top center`,
                      backgroundSize: 'cover',
                    }}
                  ></Stack>
                </Box>
                <Stack
                  sx={{
                    gap: '10px',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Typography
                    sx={{ fontSize: 32 }}
                    variant="subHeadline"
                    color={colors.gray[12]}
                  >
                    {profile?.attributes?.name}
                  </Typography>
                  <Typography variant="bodySmallBold" color={colors.primary[4]}>
                    {profile?.attributes?.title}
                  </Typography>
                  <Typography variant="subHeadline">iMoon B.V.</Typography>
                </Stack>
              </Stack>
              <Typography
                variant="subHeadline"
                sx={{ textAlign: 'center', py: 2 }}
              >
                Reach me at:
              </Typography>
              <Stack
                sx={{
                  px: 2,
                  pb: 4,
                  gap: 1,
                }}
              >
                {fieldList.map((item) => item.value && field({ ...item }))}
                {profile?.attributes?.demos && (
                  <>
                    <Divider sx={{ my: 1 }} />
                    {field({
                      label: 'Demos',
                      icon: faGamepad,
                      color: colors.gray[5],
                      href: profile?.attributes?.demos,
                      value: profile?.attributes?.demos,
                    })}
                  </>
                )}
              </Stack>
            </Stack>
          </Card>
        )}
      </PageContent>
    </Box>
  )
}

export default ProfileItem
