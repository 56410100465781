import { colors } from '../../colors'
import { Box, Card, Stack, Typography } from '@mui/material'
import { t } from 'i18next'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'

interface ComingSoonProps {
  // TODO: Convert this to accept Date and get the values with Day.js or similar library
  releaseDate: Date
}

const ComingSoon = (props: ComingSoonProps) => {
  const { t } = useTranslation()
  return (
    <Stack gap={0.75}>
      <Typography
        variant="title3"
        color="initial"
        textTransform="uppercase"
        textAlign="center"
        sx={{ color: colors.gray[12] }}
      >
        {t('products.coming_soon.title')}
      </Typography>
      <Countdown date={props.releaseDate} renderer={renderer} />
    </Stack>
  )
}

interface RendererProps {
  days: number
  hours: number
  minutes: number
  seconds: number
  completed: boolean
}

const renderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}: RendererProps) => {
  const list = [
    {
      label: t('products.coming_soon.days'),
      time: days,
    },
    {
      label: t('products.coming_soon.hours'),
      time: hours,
    },
    {
      label: t('products.coming_soon.minutes'),
      time: minutes,
    },
    {
      label: t('products.coming_soon.seconds'),
      time: seconds,
    },
  ]

  if (completed) {
    // Render a complete state
    return
  } else {
    // Render a countdown
    return (
      <Box
        sx={{
          display: 'flex',
          gap: [2, null, 3],
          justifyContent: 'center',
          '.MuiCard-root': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: [70, 80, 90],
            height: [70, 80, 90],
            borderRadius: 3,
            background: colors.gray[4],
          },
        }}
      >
        {list.map((item) => (
          <Card key={item.label}>
            <Stack sx={{ alignItems: 'center' }}>
              <Typography
                variant={'title3'}
                sx={{ fontSize: [24, 34], color: colors.gray[12] }}
              >
                {item.time}
              </Typography>
              <Typography
                variant="bodyBold"
                sx={{
                  opacity: 0.6,
                  textTransform: 'capitalize',
                  fontSize: [14, 'initial'],
                  color: colors.gray[12],
                }}
              >
                {item.label}
              </Typography>
            </Stack>
          </Card>
        ))}
      </Box>
    )
  }
}

export default ComingSoon
