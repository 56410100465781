import { Box, Stack, Typography } from '@mui/material'
import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'

import { colors } from '../../colors'
import { NewTournamentFileContent, RankTable } from './RankTable'
import { formatCurrencyAmount } from '../../helpers/helpers'
import dayjs from 'dayjs'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: colors.tertiary[8],
    color: colors.tertiary[8],
    top: -9,
    '&::after': {
      position: 'absolute',
      top: -0.45,
      left: -1,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))

export const LiveTournamentStatus = (props: {
  stats: NewTournamentFileContent
}) => {
  const { stats } = props
  return (
    <Box
      sx={{
        position: 'relative',
        background:
          'linear-gradient(180deg, rgba(42, 36, 58, 0) 0%, #2A243A 100%)',
        border: `1px solid ${colors.gray[3]}`,
        borderRadius: '12px',
        gap: 2,
        p: 1.5,
      }}
    >
      <Stack
        sx={{
          flexDirection: { xs: 'column', md: 'row' },
          gap: 6,
          whiteSpace: 'nowrap',
          textAlign: 'start',
        }}
      >
        <Stack sx={{ gap: 2 }}>
          <Stack>
            <Typography
              variant="subHeadline"
              sx={{ whiteSpace: 'nowrap', color: colors.gray[11] }}
            >
              Min Bet Amounts
            </Typography>
            {stats.currencyConditions.map((item, index) => (
              <Box
                sx={{ display: 'flex', gap: 1, color: colors.gray[9] }}
                key={index}
              >
                <Typography variant="bodyBold">{item.currency}</Typography>
                <Typography variant="bodyBold">
                  {formatCurrencyAmount(item.minBetAmount, item.currency)}
                </Typography>
              </Box>
            ))}
          </Stack>
          <Stack>
            <Typography variant="subHeadline" sx={{ color: colors.gray[11] }}>
              Tournament Duration
            </Typography>
            <Typography variant="body" sx={{ color: colors.gray[9] }}>
              {dayjs(stats.from).format('YYYY-MM-DD')} ~{' '}
              {dayjs(stats.to).format('YYYY-MM-DD')}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="subHeadline" sx={{ color: colors.gray[11] }}>
              Games
            </Typography>
            {stats.gameIds?.map((item) => (
              <Box sx={{ display: 'flex', gap: 1 }} key={item}>
                <Typography variant="body">{item}</Typography>
              </Box>
            ))}
          </Stack>
        </Stack>

        {/* <TournamentPrizes /> */}
        {/* Modals */}
        <Stack
          sx={{
            width: 1,
            height: 1,
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              background: colors.gray[4],
              py: 1.5,
              borderRadius: 2,
              display: 'flex',
              gap: 1.5,
              svg: { color: colors.tertiary[8] },
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="bodySmall"
              sx={{ fontSize: 12, color: colors.gray[8] }}
            >
              {/* {t('tournaments.live_section.live_status')} */}
              Leaderboard Status
            </Typography>
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
            ></StyledBadge>
          </Stack>
          <RankTable {...props} />
        </Stack>
        {/* <Stack sx={{ gap: 2 }}>
          <Stack>
            <Typography
              variant="subHeadline"
              sx={{ whiteSpace: 'nowrap', color: colors.gray[9] }}
            >
              Prizes
            </Typography>
            {stats.prizes.map((item) => (
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Typography variant="body">{item}</Typography>
              </Box>
            ))}
          </Stack>
        </Stack> */}
      </Stack>
    </Box>
  )
}
