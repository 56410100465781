import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

// Pass an id when calling this api to return a specific article, otherwise returns an array of all published articles
export const articleListApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API_PATH
    }articles/?populate=assets&sort=date:DESC&locale=${locale || 'en'}`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export const articleApi = async (slug: string, locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API_PATH
    }slugify/slugs/article/${slug}?populate=assets&locale=${locale || 'en'}`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export const heroSliderListApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API_PATH
    }sliders?populate=backgroundImg,backgroundImgMobile,logo&locale=${
      locale || 'en'
    }`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export const bannerOneApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API_PATH
    }banner-one?populate=deep&locale=${locale || 'en'}`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}
export const bannerProductsApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API_PATH
    }banner-product?populate=deep,7&locale=${locale || 'en'}`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

// Pass an id when calling this api to return a specific article, otherwise returns an array of all published articles
export const licenseListApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API_PATH
    }licenses?populate=logo&locale=${locale || 'en'}`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export const newProductListApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API_PATH
    }new-product?populate[games][populate][0]=coverImg,detailsPage&locale=${
      locale || 'en'
    }`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export const productListApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API_PATH
    }product?populate[games][populate][0]=coverImg,detailsPage&locale=${
      locale || 'en'
    }`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export const levelUpProductListApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API_PATH
    }level-up-product?populate[games][populate][0]=coverImg,detailsPage&locale=${
      locale || 'en'
    }`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export const gameListApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API_PATH
    }games?populate[detailsPage][fields]=id&populate=coverImg&locale=${'en'}`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export const gameDetailsApi = async (slug: string, locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API_PATH
    }games?filters[slug][$eq]=${slug}&populate=deep&locale=${locale || 'en'}`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export const gameApi = async (slug: string, locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API_PATH
    }games?filters[slug][$eq]=${slug}&locale=${locale || 'en'}`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

// Pass an id when calling this api to return a specific article, otherwise returns an array of all published articles
export const profileListApi = async (locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API_PATH
    }profiles/?populate=image&locale=${locale || 'en'}`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}
export const partnerListApi = async () => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_STRAPI_BASE_API_PATH}partners?populate=logo`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export const profileApi = async (slug: string, locale?: string) => {
  const data = JSON.stringify({})

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API_PATH
    }slugify/slugs/profile/${slug}?populate=deep&locale=${locale || 'en'}`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export const infoModalApi = async (
  gameId: string,
  version: string,
  locale?: string
) => {
  const data = JSON.stringify({})
  locale = locale === 'tl' ? 'fil' : locale

  const config: AxiosRequestConfig = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_STRAPI_BASE_API_PATH
    }information-modals?filters[gameId][$eq]=${gameId}&filters[version][$eq]=${version}&populate=pages,4&locale=${
      locale || 'en'
    }`,
    data,
  }

  try {
    const res: AxiosResponse = await axios(config)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}
