import React, { useEffect, useState } from 'react'
import { fetchJSONData } from '../../helpers/helpers'
import { RankTable } from '../../components/Tournament/RankTable'
import { Box, Stack, Typography } from '@mui/material'
import { LiveTournamentStatus } from '../../components/Tournament/LiveTournamentStatus'
import { colors } from '../../colors'
import { useSearchParams } from 'react-router-dom'

const TournamentResult = () => {
  const [tournamentData, setTournamentData] = useState<any>()
  const [searchParams, setSearchParams] = useSearchParams()
  const tid = searchParams.get('tid')

  useEffect(() => {
    const getData = async () => {
      if (tid) {
        const data = await fetchJSONData(
          `https://tournament.imoon.com/stats/${tid}-result.json`
        )

        setTournamentData(data)
      }
    }

    getData()
  }, [])

  return (
    <Stack sx={{ gap: 2, textAlign: 'center' }}>
      <Typography variant="title3">Tournament Results</Typography>
      {
        // @ts-ignore
        tournamentData?.operatorTournamentResults.map((item) => (
          <Stack sx={{ gap: 1 }} key={item.opId}>
            <Typography variant="headline" sx={{ color: colors.gray[11] }}>
              OP ID: {item.opId}
            </Typography>
            <LiveTournamentStatus stats={item} key={item.opId} />
          </Stack>
        ))
      }
    </Stack>
  )
}

export default TournamentResult
