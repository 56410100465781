import { Box, Card, CardProps, Stack, Typography } from '@mui/material'
import { colors } from '../../colors'
import { formatCurrencyAmount } from '../../helpers/helpers'

export const OddBadge = (props: { value: number }) => {
  return (
    <Stack
      sx={{
        background: '#07932C',
        height: '26px',
        width: [58, 62],
        px: 1,
        borderRadius: '8px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{
          color: '#fff',
          fontSize: [12, 14],
          fontWeight: '700',
        }}
      >
        {props.value}x
      </Typography>
    </Stack>
  )
}

interface TournamentStat {
  playerId: string
  nickname: string
  winOdds: number
}

// export interface TournamentFileContentResult {
//   tournamentId: string
//   title: string
//   from: number
//   to: number
//   currency: string
//   minBetAmount: number
//   playersCount: number
//   uniquePlayer?: boolean
//   includeTestPlayers?: boolean
//   description?: string
//   gameIds?: string[]
//   winners: {
//     playerId: string
//     nickname: string
//     winOdds: number
//   }[]
//   opId: string
//   updatedAt?: number
//   prizes: string[]
// }

export interface NewTournamentFileContent {
  opId: string
  tournamentId: string
  title: string
  description?: string
  from: number
  to: number
  currencyConditions: TournamentCurrencyConditionItem[]
  winnersCount: number
  uniqueWinnerOnly?: boolean
  gameIds?: string[]
  winners: TournamentDataItemResult[]
  updatedAt?: number
  prizes: string[]
  prizesConfig?: TournamentPrizesConfig
}

export interface TournamentPrizesConfig {
  [key: string]: {
    [key: string]: string[]
  }
}

export interface TournamentDataItemResult {
  nickname: string
  playerId: string
  winOdds: number
  opId?: string
  brandId?: string
  currency?: string
  bet: {
    betAmount: number
    betId: string
    currency: string
    gameId: string
    playerId: string
    resolvedAt: number
    status: string
    winAmount: number
  }
  prizeWon: {
    amount: number
    amountInBetCurrency: number
    betCurrency: string
    currency: string
    prizeType: string
    title: string
  }
}

export interface TournamentCurrencyConditionItem {
  currency: string
  minBetAmount: number
}

export const RankTable = ({ stats }: { stats: NewTournamentFileContent }) => {
  const customRankStyles: RankProps[] = [
    {
      badge:
        'https://staliendemostage.blob.core.windows.net/files/public/medal_1st_6137a3ebbf.png',
      background:
        'linear-gradient(89.71deg, #FFC700 -10.78%, rgba(255, 199, 0, 0) 32.05%), linear-gradient(270deg, #1B1728 7.08%, #5D5378 98.06%)',
    },
    {
      badge:
        'https://staliendemostage.blob.core.windows.net/files/public/medal_2nd_7c72e4afec.png',
      background:
        'linear-gradient(89.69deg, #393939 -27.42%, rgba(160, 159, 157, 0.863636) -18.56%, rgba(142, 142, 142, 0) 37.54%, rgba(124, 124, 124, 0) 85.95%), linear-gradient(270deg, #1B1728 7.08%, #5D5378 98.06%)',
    },
    {
      badge:
        'https://staliendemostage.blob.core.windows.net/files/public/medal_3rd_98cf7ba980.png',
      background:
        'linear-gradient(89.69deg, #744111 -27.42%, rgba(189, 102, 53, 0.863636) -18.56%, rgba(252, 123, 82, 0) 37.54%, rgba(124, 124, 124, 0) 85.95%), linear-gradient(270deg, #1B1728 7.08%, #5D5378 98.06%)',
    },
  ]

  // TODO: Make a reusable component for each row
  return (
    <Stack
      // dir={'rtl'}
      sx={{
        mt: 1,
        width: '100%',
        position: 'relative',
        overflowY: 'scroll',
      }}
      className="table--container"
    >
      <Stack sx={{ gap: 0.5, mb: 2 }}>
        {stats.winners.map((item, index) => (
          <Rank
            {...item}
            {...customRankStyles[index]}
            index={index}
            key={index}
          />
        ))}
      </Stack>
    </Stack>
  )
}

interface RankProps {
  rank?: string
  prize?: string
  background?: string
  index?: number
  badge?: string
}

const Rank = (props: RankProps & CardProps & TournamentDataItemResult) => {
  const { bet, prizeWon } = props
  return (
    // TODO: Reduce font weight for farsi
    <Card sx={{ borderRadius: 2 }}>
      <Box
        sx={{
          display: 'flex',
          height: 42,
          alignItems: 'center',
          gap: 1,
          p: 1,
          background:
            props.background ||
            'linear-gradient(90.67deg, rgba(69, 48, 138, 0.2) 1.82%, rgba(43, 37, 59, 0) 50.58%), linear-gradient(270deg, #1B1728 7.08%, #5D5378 98.06%)',
          '.MuiTypography-root': {
            // fontWeight: dir === 'rtl' ? 500 : 700,
          },
          ...props.sx,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flex: 1,
            gap: '5px',
            '.MuiTypography-root': {
              fontWeight: '700 !important',
            },
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 0.5,
              flex: 1,
              textAlign: 'start',
            }}
          >
            <Box sx={{ display: 'flex', width: 32, justifyContent: 'center' }}>
              {props.badge ? (
                <img src={props.badge} alt="" />
              ) : (
                props.index && (
                  <Typography className="rank--number">
                    {props.index + 1}
                  </Typography>
                )
              )}
            </Box>
            <Box
              sx={{
                borderLeft: `1px dotted ${colors.primary[5]} `,
                height: '30px',
              }}
            ></Box>
            <Typography
              variant="bodyBold"
              sx={{ fontSize: 12, color: colors.gray[12] }}
            >
              {props.nickname}
            </Typography>
          </Stack>
          <Stack>
            <OddBadge value={props.winOdds} />
          </Stack>
          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: 'end',
              alignItems: 'center',
              gap: 0.5,
              flex: 1,
              '.MuiTypography-root': {
                fontSize: [12, 16],
                ...(props.index === 9 && {
                  fontSize: { xs: 12, sm: 'initial' },
                }),
              },
            }}
          ></Stack>
        </Box>
        <Stack>
          <Typography variant="body">{/* {} */}</Typography>
        </Stack>
      </Box>

      <Stack sx={{ alignItems: 'start', p: 2, gap: 2 }}>
        <Typography variant="bodyBold" sx={{ color: colors.gray[10] }}>
          Player ID: {props.playerId}
        </Typography>

        <Stack sx={{ alignItems: 'start' }}>
          <Typography variant="subHeadline" sx={{ color: colors.gray[10] }}>
            Bet
          </Typography>
          <Typography
            variant="bodyBold"
            sx={{
              color: colors.gray[9],
            }}
          >
            Bet Amount:{' '}
            <Typography variant="body" sx={{ display: 'inline-block' }}>
              {bet.currency === 'USD' ? '$' : `${bet.currency} `}
              {formatCurrencyAmount(bet.betAmount, bet.currency)}
            </Typography>
          </Typography>
          <Typography
            variant="bodyBold"
            sx={{
              color: colors.gray[9],
            }}
          >
            Bet ID:{' '}
            <Typography variant="body" sx={{ display: 'inline-block' }}>
              {bet.betId}
            </Typography>
          </Typography>
          <Typography
            variant="bodyBold"
            sx={{
              color: colors.gray[9],
            }}
          >
            Game ID:{' '}
            <Typography variant="body" sx={{ display: 'inline-block' }}>
              {bet.gameId}
            </Typography>
          </Typography>
          <Typography
            variant="bodyBold"
            sx={{
              color: colors.gray[9],
            }}
          >
            Win Amount:{' '}
            <Typography variant="body" sx={{ display: 'inline-block' }}>
              {bet.currency === 'USD' ? '$' : `${bet.currency} `}
              {formatCurrencyAmount(bet.winAmount, bet.currency)}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      {/* Prize won */}
      <Stack sx={{ alignItems: 'start', p: 2 }}>
        <Typography variant="subHeadline" sx={{ color: colors.gray[10] }}>
          Prize Won
        </Typography>
        <Typography
          variant="bodyBold"
          sx={{
            color: colors.gray[9],
          }}
        >
          Title:{' '}
          <Typography variant="body" sx={{ display: 'inline-block' }}>
            {prizeWon.title}
          </Typography>
        </Typography>
        <Typography
          variant="bodyBold"
          sx={{
            color: colors.gray[9],
          }}
        >
          Amount:{' '}
          <Typography variant="body" sx={{ display: 'inline-block' }}>
            {prizeWon.currency === 'USD' ? '$' : `${prizeWon.currency} `}
            {formatCurrencyAmount(prizeWon.amount, prizeWon.currency)}
          </Typography>
        </Typography>
        <Typography
          variant="bodyBold"
          sx={{
            color: colors.gray[9],
          }}
        >
          Amount In Bet Currency:{' '}
          <Typography variant="body" sx={{ display: 'inline-block' }}>
            {prizeWon.betCurrency === 'USD' ? '$' : `${prizeWon.betCurrency} `}
            {formatCurrencyAmount(
              prizeWon.amountInBetCurrency,
              prizeWon.betCurrency
            )}
          </Typography>
        </Typography>
        <Typography
          variant="bodyBold"
          sx={{
            color: colors.gray[9],
          }}
        >
          Prize Type:{' '}
          <Typography variant="body" sx={{ display: 'inline-block' }}>
            {prizeWon.prizeType}
          </Typography>
        </Typography>
      </Stack>
    </Card>
  )
}
