import numeral from 'numeral'

export const slugify = (input: string, separator = '-') => {
  const slug = input
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, separator)
    .replace(new RegExp(`^${separator}|${separator}$`, 'g'), '')

  return slug
}

export const removeHtmlTags = (text: string) => {
  const div = document.createElement('div')
  div.innerHTML = text
  return div.textContent || div.innerText
}

export const fetchJSONData = async (url: string) => {
  try {
    const response = await fetch(url) // Fetch the JSON data
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    const jsonData = await response.json() // Parse the JSON data
    return jsonData
  } catch (error) {
    console.error('Error fetching JSON:', error)
    throw error
  }
}

export const formatCurrencyAmount = (
  number: number = 0, // Default to 0 if no number is provided
  currency: string | undefined = undefined,
  acceptedDecimals: number = 0
) => {
  let formatString = '0,00'

  if (acceptedDecimals > 0) {
    // If acceptedDecimals is greater than 0, include the decimal format
    formatString += `.[${'0'.repeat(acceptedDecimals)}]`
  } else if (acceptedDecimals === 0) {
    // If acceptedDecimals is 0, include the optional decimal format
    formatString += '.[00]'
  }

  // Apply formatting based on currency
  if (currency === 'IRT') {
    return numeral(Math.floor(number)).format('0,00')
  } else {
    return numeral(number).format(formatString)
  }
}
