import { colors } from '../../colors'
import {
  faArrowLeft,
  faArrowRight,
  faChevronDown,
  faInfoCircle,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  IconButton,
  Skeleton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import HelmetExport, { Helmet } from 'react-helmet'
import { infoModalApi } from '../../apis/strapiApi'
import numeral from 'numeral'
import i18n from '../../i18n'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

interface AccordionList {
  title: string
  details: JSX.Element
}

export interface InfoModalType {
  id?: number
  title?: string
  gameId?: string
  content?: string
  createdAt?: Date
  updatedAt?: Date
  publishedAt?: Date
  locale?: string
  pages?: PageType[]
  version?: string
  templates?: string
  note?: string
}

export interface PageType {
  id?: number
  title?: string
  content?: string
}

const replacePlaceholdersInContent = (
  content: string,
  placeholders: { placeholder: string; value?: string | null }[]
): string => {
  let updatedContent = content
  let placeholdersReplaced = true

  while (placeholdersReplaced) {
    placeholdersReplaced = false

    placeholders.forEach((placeholder) => {
      if (
        updatedContent.includes(placeholder.placeholder) &&
        placeholder.value
      ) {
        updatedContent = updatedContent.replace(
          placeholder.placeholder,
          placeholder.value
        )
        placeholdersReplaced = true
      }
    })
  }

  return updatedContent
}
const InfoModalContent = () => {
  const [infoModalData, setInfoModalData] = useState<InfoModalType>()
  const [currPage, setCurrPage] = useState<PageType>()
  const [navLevel, setNavLevel] = useState(0)
  const isRootPage = !navLevel && infoModalData?.pages
  const [accordionList, setAccordionList] = useState<AccordionList[]>()
  const [searchParams, setSearchParams] = useSearchParams()
  const gameId = searchParams.get('gameId')
  const gameTitle = searchParams.get('gameTitle')
  const version = searchParams.get('version')
  const currency = searchParams.get('currency')
  const minBetAmount = searchParams.get('minBetAmount')
  const maxBetAmount = searchParams.get('maxBetAmount')
  const maxWinAmount = searchParams.get('maxWinAmount')
  const rngVersion = searchParams.get('rngVersion')
  const gameVersion = searchParams.get('gameVersion')
  const lang = searchParams.get('lang')
  const countdownDuration = searchParams.get('countdownDuration')
  const betCloseDuration = searchParams.get('betCloseDuration')
  const templatesDom = document.createElement('div')
  const { locale } = useParams()
  const dir = i18n.dir(locale)

  let templatesStyles
  if (infoModalData?.templates) {
    templatesDom.innerHTML = infoModalData.templates
    templatesStyles = templatesDom.querySelector('style')?.innerHTML
  }

  const placeholderList = [
    {
      placeholder: '{{countdownDuration}}',
      value: `${
        countdownDuration
          ? dayjs.duration(parseInt(countdownDuration)).seconds()
          : '7'
      }`,
    },
    {
      placeholder: '{{betCloseDuration}}',
      value: `${
        betCloseDuration
          ? dayjs.duration(parseInt(betCloseDuration)).seconds()
          : '2'
      }`,
    },
    { placeholder: '{{currency}}', value: currency?.toUpperCase() },
    {
      placeholder: '{{minBetAmount}}',
      value: numeral(minBetAmount).format('0,00.[000000000000]'),
    },
    {
      placeholder: '{{maxBetAmount}}',
      value: numeral(maxBetAmount)?.format('0,00.[000000000000]'),
    },
    {
      placeholder: '{{maxWinAmount}}',
      value: numeral(maxWinAmount)?.format('0,00.[000000000000]'),
    },
    {
      placeholder: '{{rngVersion}}',
      value: rngVersion,
    },
    {
      placeholder: '{{gameId}}',
      value: gameId,
    },
    {
      placeholder: '{{gameTitle}}',
      value: gameTitle || 'Crash',
    },
    {
      placeholder: '{{gameVersion}}',
      value: gameVersion,
    },
    {
      placeholder: '{{lang}}',
      value: lang,
    },
    {
      placeholder: '{{info_crash_max}}',
      value: templatesDom.querySelector('#info_crash_max')?.outerHTML,
    },
    {
      placeholder: '{{info_crash_double}}',
      value: templatesDom.querySelector('#info_crash_double')?.outerHTML,
    },
    {
      placeholder: '{{info_crash_half}}',
      value: templatesDom.querySelector('#info_crash_half')?.outerHTML,
    },
    {
      placeholder: '{{info_crash_min}}',
      value: templatesDom.querySelector('#info_crash_min')?.outerHTML,
    },
    {
      placeholder: '{{info_crash_plusminus}}',
      value: templatesDom.querySelector('#info_crash_plusminus')?.outerHTML,
    },
    {
      placeholder: '{{info_crash_players}}',
      value: templatesDom.querySelector('#info_crash_players')?.outerHTML,
    },
    {
      placeholder: '{{info_crash_bet_btns}}',
      value: templatesDom.querySelector('#info_crash_bet_btns')?.outerHTML,
    },
    {
      placeholder: '{{info_crash_overunder}}',
      value: templatesDom.querySelector('#info_crash_overunder')?.outerHTML,
    },
    {
      placeholder: '{{info_crash_overunder_btns}}',
      value: templatesDom.querySelector('#info_crash_overunder_btns')
        ?.outerHTML,
    },
    {
      placeholder: '{{info_crash_cashout_btn}}',
      value: templatesDom.querySelector('#info_crash_cashout_btn')?.outerHTML,
    },
  ]

  useEffect(() => {
    const getData = async () => {
      if (gameId && version) {
        let infoModalGameId

        // If less than 2000 show crash info modal, otherwise show jogo do bicho
        if (Number(gameId) < 2000) {
          infoModalGameId = '1001'
        } else {
          infoModalGameId = '2001'
        }

        try {
          const data = await infoModalApi(infoModalGameId, version, locale)

          setCurrPage(data?.data[0].attributes)
          setInfoModalData(data?.data[0].attributes)
        } catch (error) {
          // Fallback to en
          const data = await infoModalApi(gameId, '1', 'en')
          setCurrPage(data?.data[0].attributes)
          setInfoModalData(data?.data[0].attributes)
        }
      }
    }
    getData()
  }, [])

  useEffect(() => {
    setAccordionList([])

    const contentDom = document.getElementById(`${currPage?.id}__content`)
    const noteDom = document.getElementById(`infoModal__note`)
    if (contentDom && currPage?.content) {
      let content = currPage.content

      // Replace IFRAME_WRAPPER with iframe tag
      if (content.includes('IFRAME_WRAPPER')) {
        const parser = new DOMParser()
        const iframeWrapper = parser.parseFromString(content, 'text/html')
        const dataUrl = iframeWrapper
          .querySelector('.IFRAME_WRAPPER')
          ?.getAttribute('data-url')

        content = `<iframe src=${dataUrl} style='display: none' />`
      }

      // Replace placeholders in content
      if (placeholderList)
        content = replacePlaceholdersInContent(content, placeholderList)

      contentDom.innerHTML = content.split('<h3')[0]

      const iframe = contentDom.querySelector('iframe')
      if (iframe) {
        iframe.onload = () => {
          iframe.style.display = 'block'
        }
      }

      const accordionDom = document.createElement('div')
      accordionDom.innerHTML = content

      // What happens when there's no h3?....
      const h3Dom = accordionDom.querySelectorAll('h3')

      h3Dom.forEach((h3) => {
        if (h3.textContent && h3.nextElementSibling) {
          setAccordionList((prev) => [
            ...(prev || []),
            {
              title: h3.textContent || '',
              details: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: h3.nextElementSibling?.innerHTML || '',
                  }}
                />
              ),
            },
          ])
        }
      })

      if (noteDom?.innerHTML && infoModalData?.note) {
        noteDom.innerHTML = infoModalData.note
      }
    }
  }, [currPage])

  // handle nav click
  const handleClick = (id: number) => {
    setNavLevel((prev) => prev + 1)
    const newPage = infoModalData?.pages?.filter((page) => page.id === id)[0]
    newPage && setCurrPage(newPage)
  }

  const handleBackClick = () => {
    setNavLevel((prev) => prev - 1)
    setCurrPage(infoModalData)
  }

  return (
    <>
      <Helmet>
        <style>{templatesStyles}</style>
      </Helmet>
      <Stack
        id="infoModalContainer"
        sx={{
          width: 1,
          alignItems: 'center',
          color: colors.gray[11],
          img: { maxWidth: '100%' },
          '*': {
            fontFamily: `${
              dir === 'rtl' ? 'Iranyekan' : '"Nunito Sans"'
            }, sans-serif !important`,
          },
          'img, video': { borderRadius: 3 },
          '.MuiTypography-body1': {
            fontWeight: 400,
            fontSize: 16,
          },
        }}
        dir={dir}
      >
        <AppBar position="sticky" elevation={0}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: 1,
              gap: 0.5,
              '.MuiButtonBase-root': {
                marginInlineStart: -1,
                p: 0.75,
              },
              px: 2,
            }}
            disableGutters
          >
            <Box sx={{ svg: { width: 20, height: 20 } }}>
              {navLevel ? (
                <IconButton sx={{ color: 'white' }} onClick={handleBackClick}>
                  <FontAwesomeIcon
                    icon={dir === 'rtl' ? faArrowRight : faArrowLeft}
                  />
                </IconButton>
              ) : (
                <IconButton disabled>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </IconButton>
              )}
            </Box>
            <Typography variant="headline" sx={{ color: colors.gray[12] }}>
              {currPage?.title || (
                <Skeleton variant="text" sx={{ width: '11ch' }} />
              )}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ width: 1, height: 1 }}>
          <Stack
            sx={{ width: 1, gap: 2, px: 2, ...(!isRootPage && { height: 1 }) }}
          >
            {currPage ? (
              <Box
                id={`${currPage.id}__content`}
                sx={{
                  width: 1,
                  height: accordionList ? 'auto' : 1,
                  iframe: { height: 1 },
                  fontSize: 16,
                }}
              >
                <Skeleton
                  variant="text"
                  sx={{ width: 100, height: '24px', py: 2 }}
                />
              </Box>
            ) : (
              <Typography sx={{ width: 1 }}>
                <Skeleton
                  variant="text"
                  sx={{ width: 1, height: '24px', py: 2 }}
                />
              </Typography>
            )}
            {accordionList && (
              <Box
                sx={{
                  '	.MuiAccordion-root': {
                    background: colors.gray[4],
                  },
                  '.MuiPaper-root::before': {
                    background: colors.gray[3],
                  },
                }}
              >
                {accordionList.map((item) => (
                  <Accordion
                    key={item.title}
                    sx={{
                      width: 1,
                      p: {
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '147%',
                        color: colors.gray[12],
                      },
                    }}
                    elevation={0}
                  >
                    <AccordionSummary
                      expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    >
                      <Typography variant="bodyBold">{item.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>{item.details}</AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            )}
          </Stack>
          {isRootPage && (
            <>
              <Stack sx={{ gap: 1, width: 1, px: 2 }}>
                {infoModalData?.pages?.map((page) => (
                  <NavItem
                    label={page.title || ''}
                    key={page.id}
                    onClick={() => handleClick(page.id || 0)}
                  />
                ))}
                {infoModalData.note && (
                  <Box
                    id={`infoModal__note`}
                    sx={{
                      width: 1,
                      height: accordionList ? 'auto' : 1,
                      iframe: { height: 1 },
                      fontSize: 16,
                    }}
                  >
                    <Skeleton
                      variant="text"
                      sx={{ width: 100, height: '24px', py: 2 }}
                    />
                  </Box>
                )}
              </Stack>
            </>
          )}
        </Box>
      </Stack>
    </>
  )
}

interface NavItemProps {
  label: string
  onClick: () => void
}

const NavItem = (props: NavItemProps) => {
  return (
    <Button
      sx={{
        height: '48px',
        background:
          'linear-gradient(90deg, rgb(42, 36, 59) 0%, rgb(54, 46, 74) 105.73%)',
        color: colors.gray[8],
        fontWeight: 700,
        fontSize: 16,
        borderRadius: 3,
        '&:hover': {
          opacity: 0.8,
        },
        textTransform: 'capitalize',
      }}
      fullWidth
      onClick={props.onClick}
    >
      {props.label}
    </Button>
  )
}

export default InfoModalContent
