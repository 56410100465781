import {
  Box,
  Button,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { colors } from '../../../colors'
import { PlayDemoButton } from '../../../components'
import { CrashOverview } from '../../../types'
import { useTranslation } from 'react-i18next'

// TODO: Move sx styles to a separate file
// TODO: Move data to a separate file
export const Overview = (props: CrashOverview) => {
  const { product } = useParams()
  const { t } = useTranslation()

  const tableData = [
    { name: t('products.details.type'), value: props.type },
    {
      name: t('products.details.max_odds'),
      value: props.maxOdds && `${props.maxOdds}x`,
    },
    {
      name: t('products.details.rtp'),
      value: props.rtp && `${props.rtp}%`,
    },
    {
      name: t('products.details.release_date'),
      value:
        props.releaseDate && dayjs(props.releaseDate).format('MMM D, YYYY'),
    },
    { name: t('products.details.resolution'), value: props.resolution },
  ]

  useEffect(() => {
    const textDom = document.getElementById(`overview__text`)
    if (textDom && props.text) textDom.innerHTML = props.text
  }, [props])

  return (
    <Stack
      sx={{
        flexDirection: { xs: 'column', lg: 'row' },
        gap: 3,
        img: { borderRadius: 3 },
        '.MuiButton-root': {
          whiteSpace: 'nowrap',
        },
        mb: 2,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          img: { position: 'relative', width: 1, zIndex: 1 },
          height: ['calc(72.2vw - 24px)', 423, 523, 483],
          width: { lg: 639 },
          mx: { sm: 10, lg: 0 },

          '.MuiSkeleton-root': {
            position: 'absolute',
            inset: 0,
            height: 1,
            borderRadius: 3,
          },
        }}
      >
        {props.gameplayImg ? (
          <img src={props.gameplayImg.data?.attributes?.url} alt={`gameplay`} />
        ) : (
          <Skeleton variant="rectangular" />
        )}
      </Box>
      <Stack sx={{ flex: 1.25, gap: 3 }}>
        <Typography
          component="div"
          variant="body"
          sx={{
            color: colors.gray[9],
            p: { margin: '0 !important' },
          }}
          id="overview__text"
        >
          {['', '', '', '', '', '', '', '', ''].map((item, index) => (
            <Skeleton variant="text" key={index} />
          ))}
        </Typography>

        <TableContainer>
          <Table size="small">
            <TableBody>
              {tableData.map((data) => (
                <TableRow key={data.name}>
                  <TableCell>{data.name}</TableCell>
                  <TableCell>
                    {data.value || <Skeleton variant="text"></Skeleton>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack
          sx={{ flexDirection: 'row', gap: 2 }}
          className="overview__buttons"
        >
          {/* TODO: refactor, move the conditions inside a const */}
          {props.gameId && props.slug ? (
            <PlayDemoButton
              slug={props.slug}
              gameId={props.gameId}
              variant="outlined"
              color="secondary"
              fullWidth
            />
          ) : (
            <Skeleton
              variant="rectangular"
              sx={{ width: 1, borderRadius: 2, height: 40 }}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}
